import { FilterOption } from 'interfaces/components/filter';
import {
  labelMedian,
  labelTopQuatile,
  labelYourCompany,
  medianColor,
  topQuartileColor,
  yourCompanyColor
} from 'utils/constants';
export type CompanyMetrics = {
  currentARR: number | null;
  yoyARR: number | null;
  fcfMargin: number | null;
  totalHeadcount: number | null;
  companyWebsite: string;
  grossMargin: number | null;
  netDollarRetention: number | null;
  netMagicNumber: number | null;
  burnMultiple: number | null;
  quickRatio: number | null;
  rD: number | null;
  gA: number | null;
  sM: number | null;
  rDHead: number | null;
  gAHead: number | null;
  sMHead: number | null;
};

export interface ChartDataMap {
  ARR_EOP: number;
  ARR_PER_FTE: number;
  ARR_RANGE: string;
  ARR_YYGROWTH_EOP: number;
  BURN_MULTIPLE: number;
  EMPLOYEES_PERCTOTAL_GA: number | null;
  EMPLOYEES_PERCTOTAL_RD: number | null;
  EMPLOYEES_PERCTOTAL_SM: number | null;
  FCF: number;
  GEOGRAPHY: String;
  GROWTH_MOTION: string;
  INDUSTRY: String;
  MAGICNUMBER_NET: number;
  MARGIN_FCF: number;
  MARGIN_GROSSPROFIT: number;
  MARGIN_OPEX: number;
  MARGIN_OPEX_GA: number;
  MARGIN_OPEX_RD: number;
  MARGIN_OPEX_SM: number;
  PERIODEND: string;
  PRIMARY_CUSTOMER_TARGET: string;
  QUICK_RATIO_ARR: number;
  RETENTION_NET_ARR_ANNUALIZED: number;
  RULE_OF_40: number;
  SALES_MOTION: string;
  SECTOR: string;
}

export const ChartLabelsV2 = [
  '<$25M',
  '$25-$50M',
  '$50-$100M',
  '$100-$200M',
  '>$200M'
];

export const ChartLabels = [
  '<$10M',
  '$10-$25M',
  '$25-$50M',
  '$50-$100M',
  '$100-$150M',
  '$150-$200M',
  '>$200M'
];

export enum sortDir {
  ASC = 'asc',
  DESC = 'desc'
}

// do not show ARR group data unless filtered results exceed this value
// const INSUFFICIENT_RESULTS_THRESHOLD_8 = 8;
const NSIZE_8 = 8;
const NSIZE_10 = 10;

// generate the array for each graph
export const mapCaculatedData = (
  map: Map<string, number[]>,
  nSize: number,
  dir?: sortDir
) => {
  const quartileArray: (number | null)[] = [];
  const medianArrray: (number | null)[] = [];
  const averageArrray: (number | null)[] = [];

  if (dir == undefined) dir = sortDir.ASC;

  ChartLabelsV2.forEach(label => {
    const chartArray = map.get(label);
    // if (chartArray && chartArray?.length > INSUFFICIENT_RESULTS_THRESHOLD) {
    if (chartArray && chartArray?.length > nSize) {
      const quartile = calculateQuartile(chartArray || [], dir);
      const median = calculateMedian(chartArray || []);
      const average = calculateAverage(chartArray || []);
      quartileArray.push(quartile !== undefined ? quartile : null);
      medianArrray.push(median !== undefined ? median : null);
      averageArrray.push(average !== undefined ? average : null);
    } else {
      quartileArray.push(null);
      medianArrray.push(null);
      averageArrray.push(null);
    }
  });

  return {
    quartileArray,
    medianArrray,
    averageArrray
  };
};

export const applyFiltersToChartData = (
  chartData: ChartDataMap[],
  selectedARRRange: FilterOption[],
  selectedGrowthMotion: FilterOption[],
  selectedSftwareSector: FilterOption[],
  selectedCustomerTarget: FilterOption[]
): ChartDataMap[] => {
  return chartData.filter(data => {
    const matchesARRRange =
      selectedARRRange.length > 0
        ? selectedARRRange.some(option => option.value.includes(data.ARR_RANGE))
        : true;

    const matchesGrowthMotion =
      selectedGrowthMotion.length > 0
        ? selectedGrowthMotion.some(option =>
            option.value.includes(data.GROWTH_MOTION)
          )
        : true;

    const matchesIndustry =
      selectedSftwareSector.length > 0
        ? selectedSftwareSector.some(option =>
            option.value.includes(data.SECTOR)
          )
        : true;

    const matchesCustomerTarget =
      selectedCustomerTarget.length > 0
        ? selectedCustomerTarget.some(option =>
            option.value.includes(data.PRIMARY_CUSTOMER_TARGET)
          )
        : true;

    return (
      matchesARRRange &&
      matchesGrowthMotion &&
      matchesIndustry &&
      matchesCustomerTarget
    );
  });
};

//checks which label the value goes to
const getARRIndex = (currentARR: number): number => {
  if (currentARR < 25000000) return 0;
  if (currentARR >= 25000000 && currentARR < 50000000) return 1;
  if (currentARR >= 50000000 && currentARR < 100000000) return 2;
  if (currentARR >= 100000000 && currentARR < 200000000) return 3;
  return 4;
};

// generate datasets for the graphs
export const mapChartData = (
  chartData: ChartDataMap[],
  companyMetrics: CompanyMetrics,
  arr: FilterOption[],
  growthMotion: FilterOption[],
  softwareSector: FilterOption[],
  primaryCustomer: FilterOption[]
) => {
  const arrIndex = getARRIndex(companyMetrics.currentARR || 0);

  const arrPerFTEValue =
    companyMetrics.totalHeadcount && companyMetrics.totalHeadcount > 0
      ? (companyMetrics.currentARR || 0) / companyMetrics.totalHeadcount
      : 0;

  const allValuesAreNull = (arr: any[]): boolean => {
    return arr.every(value => value === null);
  };

  let arrGrowthMap = new Map<string, number[]>();
  let netDollarRetentionMap = new Map<string, number[]>();
  let netMagicNumberMap = new Map<string, number[]>();
  let growthMarginMap = new Map<string, number[]>();
  let ruleof40Map = new Map<string, number[]>();
  let burnMultipleMap = new Map<string, number[]>();
  let ARRperFTEMap = new Map<string, number[]>();
  let OpExMapRD = new Map<string, number[]>();
  let OpExMapSM = new Map<string, number[]>();
  let OpExMapGA = new Map<string, number[]>();
  let fcfMarginMap = new Map<string, number[]>();
  let quickRatioMap = new Map<string, number[]>();
  let headCountDistributionMapRD = new Map<string, number[]>();
  let headCountDistributionMapSM = new Map<string, number[]>();
  let headCountDistributionMapGA = new Map<string, number[]>();

  const filteredChartData = applyFiltersToChartData(
    chartData,
    arr,
    growthMotion,
    softwareSector,
    primaryCustomer
  );

  filteredChartData.forEach((chart: ChartDataMap) => {
    if (chart.ARR_YYGROWTH_EOP !== null) {
      arrGrowthMap.set(chart.ARR_RANGE, [
        ...(arrGrowthMap.get(chart.ARR_RANGE) || []),
        chart.ARR_YYGROWTH_EOP
      ]);
    }

    if (chart.RETENTION_NET_ARR_ANNUALIZED !== null) {
      netDollarRetentionMap.set(chart.ARR_RANGE, [
        ...(netDollarRetentionMap.get(chart.ARR_RANGE) || []),
        chart.RETENTION_NET_ARR_ANNUALIZED
      ]);
    }

    if (chart.MAGICNUMBER_NET !== null) {
      netMagicNumberMap.set(chart.ARR_RANGE, [
        ...(netMagicNumberMap.get(chart.ARR_RANGE) || []),
        chart.MAGICNUMBER_NET
      ]);
    }

    if (chart.MARGIN_GROSSPROFIT !== null) {
      growthMarginMap.set(chart.ARR_RANGE, [
        ...(growthMarginMap.get(chart.ARR_RANGE) || []),
        chart.MARGIN_GROSSPROFIT
      ]);
    }

    if (chart.RULE_OF_40 !== null) {
      ruleof40Map.set(chart.ARR_RANGE, [
        ...(ruleof40Map.get(chart.ARR_RANGE) || []),
        chart.RULE_OF_40
      ]);
    }

    if (chart.BURN_MULTIPLE !== null) {
      burnMultipleMap.set(chart.ARR_RANGE, [
        ...(burnMultipleMap.get(chart.ARR_RANGE) || []),
        chart.BURN_MULTIPLE
      ]);
    }

    if (chart.ARR_PER_FTE !== null) {
      ARRperFTEMap.set(chart.ARR_RANGE, [
        ...(ARRperFTEMap.get(chart.ARR_RANGE) || []),
        chart.ARR_PER_FTE
      ]);
    }

    if (chart.MARGIN_OPEX_RD !== null) {
      OpExMapRD.set(chart.ARR_RANGE, [
        ...(OpExMapRD.get(chart.ARR_RANGE) || []),
        chart.MARGIN_OPEX_RD
      ]);
    }

    if (chart.MARGIN_OPEX_SM !== null) {
      OpExMapSM.set(chart.ARR_RANGE, [
        ...(OpExMapSM.get(chart.ARR_RANGE) || []),
        chart.MARGIN_OPEX_SM
      ]);
    }

    if (chart.MARGIN_OPEX_GA !== null) {
      OpExMapGA.set(chart.ARR_RANGE, [
        ...(OpExMapGA.get(chart.ARR_RANGE) || []),
        chart.MARGIN_OPEX_GA
      ]);
    }

    if (chart.MARGIN_FCF !== null) {
      fcfMarginMap.set(chart.ARR_RANGE, [
        ...(fcfMarginMap.get(chart.ARR_RANGE) || []),
        chart.MARGIN_FCF
      ]);
    }

    if (chart.QUICK_RATIO_ARR !== null) {
      quickRatioMap.set(chart.ARR_RANGE, [
        ...(quickRatioMap.get(chart.ARR_RANGE) || []),
        chart.QUICK_RATIO_ARR
      ]);
    }

    if (chart.EMPLOYEES_PERCTOTAL_RD !== null) {
      headCountDistributionMapRD.set(chart.ARR_RANGE, [
        ...(headCountDistributionMapRD.get(chart.ARR_RANGE) || []),
        chart.EMPLOYEES_PERCTOTAL_RD
      ]);
    }

    if (chart.EMPLOYEES_PERCTOTAL_SM !== null) {
      headCountDistributionMapSM.set(chart.ARR_RANGE, [
        ...(headCountDistributionMapSM.get(chart.ARR_RANGE) || []),
        chart.EMPLOYEES_PERCTOTAL_SM
      ]);
    }

    if (chart.EMPLOYEES_PERCTOTAL_GA !== null) {
      headCountDistributionMapGA.set(chart.ARR_RANGE, [
        ...(headCountDistributionMapGA.get(chart.ARR_RANGE) || []),
        chart.EMPLOYEES_PERCTOTAL_GA
      ]);
    }
  });

  function generateBubbleData(
    arrYygrowthEOP: (number | null)[],
    marginFCF: (number | null)[],
    ruleOf40Array: (number | null)[]
  ) {
    const dataSet = [];

    for (
      let i = 0;
      i <
      Math.min(
        arrYygrowthEOP.length,
        marginFCF.length,
        ruleOf40Array.length,
        5
      );
      i++
    ) {
      const x = arrYygrowthEOP[i];
      const y = marginFCF[i];
      const ro40 = ruleOf40Array[i];

      dataSet.push({
        x: x != null ? x * 100.0 : x,
        y: y != null ? y * 100.0 : y,
        r:
          i === 0
            ? 10
            : i === 1
            ? 20
            : i === 2
            ? 30
            : i === 3
            ? 40
            : i === 4
            ? 50
            : null,
        ruleOf40: ro40 != null ? ro40 * 100.0 : ro40
      });
    }

    // return dataSet; // temp until we refactor
    return dataSet.filter(object => object.r !== 10);
  }

  function generateBubbleDataCompany() {
    let radio = null;

    radio =
      arrIndex === 0
        ? 10
        : arrIndex === 1
        ? 20
        : arrIndex === 2
        ? 30
        : arrIndex === 3
        ? 40
        : arrIndex === 4
        ? 50
        : null;

    const x = companyMetrics.yoyARR === 0 ? null : companyMetrics.yoyARR;
    const y = companyMetrics.fcfMargin === 0 ? null : companyMetrics.fcfMargin;
    const r = companyMetrics.yoyARR === 0 ? null : radio;
    let ruleOf40 = null;
    if (x && y) ruleOf40 = x + y;

    const dataSet = [{ x, y, r, ruleOf40 }];

    return dataSet;
  }

  const yourCompanyFormated = (value: number, isDollar?: boolean) => {
    let arr = [];

    if (value && !isDollar) {
      arr = Array(ChartLabelsV2.length)
        .fill(null)
        .map((_, i) => (i === arrIndex ? value : null));
    } else if (value && isDollar === true) {
      arr = Array(ChartLabelsV2.length)
        .fill(null)
        .map((_, i) => (i === arrIndex ? Math.round(value / 1000) : null));
    } else {
      arr = Array(ChartLabelsV2.length).fill(null);
    }

    return arr;
  };

  const yourCompanyHeadcountDistribution = calculateHeadcountDistribution(
    companyMetrics.gAHead || 0,
    companyMetrics.rDHead || 0,
    companyMetrics.sMHead || 0
  );

  const datasets = {
    YoYARRGrowth: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(arrGrowthMap, NSIZE_8)?.quartileArray?.map(
          value => value && value * 100
        ),
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(arrGrowthMap, NSIZE_8)?.medianArrray?.map(
          value => value && value * 100
        ),
        backgroundColor: medianColor
      },
      !allValuesAreNull(yourCompanyFormated(companyMetrics.yoyARR || 0)) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.yoyARR || 0),
        backgroundColor: yourCompanyColor
      }
    ],

    netDollarRetention: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(
          netDollarRetentionMap,
          NSIZE_8
        )?.quartileArray?.map(value => value && value * 100),
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(
          netDollarRetentionMap,
          NSIZE_8
        )?.medianArrray?.map(value => value && value * 100),
        backgroundColor: medianColor
      },
      !allValuesAreNull(
        yourCompanyFormated(companyMetrics.netDollarRetention || 0)
      ) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.netDollarRetention || 0),
        backgroundColor: yourCompanyColor
      }
    ],

    netMagicNumber: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(netMagicNumberMap, NSIZE_10).quartileArray,
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(netMagicNumberMap, NSIZE_10).medianArrray,
        backgroundColor: medianColor
      },
      !allValuesAreNull(
        yourCompanyFormated(companyMetrics.netMagicNumber || 0)
      ) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.netMagicNumber || 0),
        backgroundColor: yourCompanyColor
      }
    ],

    grossMargin: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(growthMarginMap, NSIZE_8)?.quartileArray?.map(
          value => value && value * 100
        ),
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(growthMarginMap, NSIZE_8)?.medianArrray?.map(
          value => value && value * 100
        ),
        backgroundColor: medianColor
      },
      !allValuesAreNull(
        yourCompanyFormated(companyMetrics.grossMargin || 0)
      ) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.grossMargin || 0),
        backgroundColor: yourCompanyColor
      }
    ],
    burnMultiple: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(burnMultipleMap, NSIZE_8, sortDir.DESC)
          .quartileArray,
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(burnMultipleMap, NSIZE_8, sortDir.DESC)
          .medianArrray,
        backgroundColor: medianColor
      },
      !allValuesAreNull(
        yourCompanyFormated(companyMetrics.burnMultiple || 0)
      ) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.burnMultiple || 0),
        backgroundColor: yourCompanyColor
      }
    ],
    ARRperFTE: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(ARRperFTEMap, NSIZE_8)?.quartileArray?.map(
          value => value && Math.round(value / 1000)
        ),
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(ARRperFTEMap, NSIZE_8)?.medianArrray?.map(
          value => value && Math.round(value / 1000)
        ),
        backgroundColor: medianColor
      },
      !allValuesAreNull(yourCompanyFormated(arrPerFTEValue)) && {
        label: labelYourCompany,
        data: yourCompanyFormated(arrPerFTEValue, true),
        backgroundColor: yourCompanyColor
      }
    ],

    FCFMargin: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(fcfMarginMap, NSIZE_8).quartileArray?.map(
          value => value && value * 100
        ),
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(fcfMarginMap, NSIZE_8).medianArrray?.map(
          value => value && value * 100
        ),
        backgroundColor: medianColor
      },
      !allValuesAreNull(yourCompanyFormated(companyMetrics.fcfMargin || 0)) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.fcfMargin || 0),
        backgroundColor: yourCompanyColor
      }
    ],
    quickRatio: [
      {
        label: labelTopQuatile,
        data: mapCaculatedData(quickRatioMap, NSIZE_10).quartileArray,
        backgroundColor: topQuartileColor
      },
      {
        label: labelMedian,
        data: mapCaculatedData(quickRatioMap, NSIZE_10).medianArrray,
        backgroundColor: medianColor
      },
      !allValuesAreNull(
        yourCompanyFormated(companyMetrics.quickRatio || 0)
      ) && {
        label: labelYourCompany,
        data: yourCompanyFormated(companyMetrics.quickRatio || 0),
        backgroundColor: yourCompanyColor
      }
    ],

    ruleOf40: [
      {
        label: `${labelTopQuatile} - Bubble size denotes ARR range`,
        data: generateBubbleData(
          mapCaculatedData(arrGrowthMap, NSIZE_8)?.quartileArray || [],
          mapCaculatedData(fcfMarginMap, NSIZE_8)?.quartileArray || [],
          mapCaculatedData(ruleof40Map, NSIZE_8)?.quartileArray || []
        ),
        backgroundColor: 'rgba(77, 185, 107, 0.5)'
      },
      {
        label: labelMedian,
        data: generateBubbleData(
          mapCaculatedData(arrGrowthMap, NSIZE_8).medianArrray || [],
          mapCaculatedData(fcfMarginMap, NSIZE_8).medianArrray || [],
          mapCaculatedData(ruleof40Map, NSIZE_8)?.medianArrray || []
        ),
        backgroundColor: 'rgba(112, 167, 178, 0.5)'
      },
      companyMetrics.yoyARR && {
        label: labelYourCompany,
        data: generateBubbleDataCompany(),
        backgroundColor: 'rgba(86, 86, 177, 0.5)'
      }
    ]
  };

  const datasetsStacked = {
    OpEx: [
      [],

      [
        {
          label: `${labelMedian} (S&M)`,
          data: mapCaculatedData(OpExMapSM, NSIZE_8).medianArrray?.map(
            value => value && value * 100
          ),
          backgroundColor: '#4E7B84',
          stack: 'median'
        },
        {
          label: `${labelMedian} (R&D)`,
          data: mapCaculatedData(OpExMapRD, NSIZE_8).medianArrray?.map(
            value => value && value * 100
          ),
          backgroundColor: '#70A7B2',
          stack: 'median'
        },
        {
          label: `${labelMedian} (G&A)`,
          data: mapCaculatedData(OpExMapGA, NSIZE_8).medianArrray?.map(
            value => value && value * 100
          ),
          backgroundColor: '#8ABBC5',
          stack: 'median'
        }
      ],
      [
        !allValuesAreNull(yourCompanyFormated(companyMetrics.sM || 0)) && {
          label: `${labelYourCompany} (S&M)`,
          data: yourCompanyFormated(companyMetrics.sM || 0),
          backgroundColor: '#39397A',
          stack: 'Your Company'
        },
        !allValuesAreNull(yourCompanyFormated(companyMetrics.rD || 0)) && {
          label: `${labelYourCompany} (R&D)`,
          data: yourCompanyFormated(companyMetrics.rD || 0),
          backgroundColor: '#5656B1',
          stack: 'Your Company'
        },
        !allValuesAreNull(yourCompanyFormated(companyMetrics.gA || 0)) && {
          label: `${labelYourCompany} (G&A)`,
          data: yourCompanyFormated(companyMetrics.gA || 0),
          backgroundColor: '#9292E1',
          stack: 'Your Company'
        }
      ]
    ],

    headcountDistribution: [
      [
        {
          label: 'Compass SaaS Benchmark Average (R&D)',
          data: mapCaculatedData(
            headCountDistributionMapRD,
            NSIZE_8
          ).averageArrray?.map(value => value && value * 100),
          backgroundColor: '#14662F',
          stack: 'ICONIQ Growth Average'
        },
        {
          label: 'Compass SaaS Benchmark Average (G&A)',
          data: mapCaculatedData(
            headCountDistributionMapGA,
            NSIZE_8
          ).averageArrray?.map(value => value && value * 100),
          backgroundColor: '#409958',
          stack: 'ICONIQ Growth Average'
        },
        {
          label: 'Compass SaaS Benchmark Average (S&M)',
          data: mapCaculatedData(
            headCountDistributionMapSM,
            NSIZE_8
          ).averageArrray?.map(value => value && value * 100),
          backgroundColor: '#4DB96B',
          stack: 'ICONIQ Growth Average'
        }
      ],

      [],

      [
        !allValuesAreNull(
          yourCompanyFormated(yourCompanyHeadcountDistribution.rD)
        ) && {
          label: "Your Company's Data (R&D)",
          data: yourCompanyFormated(yourCompanyHeadcountDistribution.rD),
          backgroundColor: '#39397A',
          stack: 'Your Company'
        },
        !allValuesAreNull(
          yourCompanyFormated(yourCompanyHeadcountDistribution.gA)
        ) && {
          label: "Your Company's Data (G&A)",
          data: yourCompanyFormated(yourCompanyHeadcountDistribution.gA),
          backgroundColor: '#5656B1',
          stack: 'Your Company'
        },
        !allValuesAreNull(
          yourCompanyFormated(yourCompanyHeadcountDistribution.sM)
        ) && {
          label: "Your Company's Data (S&M)",
          data: yourCompanyFormated(yourCompanyHeadcountDistribution.sM),
          backgroundColor: '#9292E1',
          stack: 'Your Company'
        }
      ]
    ]
  };
  return { datasets: datasets, datasetsStacked: datasetsStacked };
};

// defaults to "inclusive" mode
// optional `exclusive` boolean param to use "exclusive" mode
export const calculateQuartile = (
  data: number[],
  dir: sortDir = sortDir.ASC,
  exclusive?: boolean
): number | undefined => {
  return exclusive
    ? calculateQuartileExlusive(data, dir)
    : calculateQuartileInclusive(data, dir);
};

// this is an "inclusive" version of caculating Quartile that includes the median value
export const calculateQuartileInclusive = (
  data: number[],
  dir: sortDir = sortDir.ASC
): number | undefined => {
  const filteredData = data.filter(
    value => value !== undefined && !isNaN(value) && value !== null
  );

  if (filteredData.length === 0) return undefined;

  // Sort the data array
  // const sortedData = filteredData.slice().sort((a, b) => a - b);

  const sortDirection =
    dir === sortDir.ASC
      ? (a: number, b: number) => a - b
      : (a: number, b: number) => b - a;
  const sortedData = filteredData.slice().sort(sortDirection);

  const n = sortedData.length;
  const q3Index = 0.75 * (n - 1);

  // Calculate the third quartile (75th percentile)
  if (q3Index % 1 === 0) {
    // Check if q3Index is an integer
    return sortedData[q3Index];
  } else {
    // Interpolate between the two nearest points
    const lower = Math.floor(q3Index);
    const upper = Math.ceil(q3Index);
    return (
      sortedData[lower] +
      (sortedData[upper] - sortedData[lower]) * (q3Index - lower)
    );
  }
};

// this is an "exclsive" version of caculating Quartile that excludes the median value
export const calculateQuartileExlusive = (
  data: number[],
  dir: sortDir = sortDir.ASC
): number | undefined => {
  const filteredData = data.filter(
    value => value !== undefined && !isNaN(value) && value !== null
  );

  if (filteredData.length === 0) return undefined;

  // const sortedArray = filteredData.sort((a, b) => a - b);
  const sortDirection =
    dir === sortDir.ASC
      ? (a: number, b: number) => a - b
      : (a: number, b: number) => b - a;
  const sortedArray = filteredData.slice().sort(sortDirection);

  const quartileIndex = Math.ceil((3 * sortedArray.length) / 4) - 1;

  return sortedArray[quartileIndex];
};

export const calculateMedian = (arr: number[]): number | undefined => {
  const filteredArray = arr.filter(
    value => value !== undefined && !isNaN(value) && value !== null
  );

  if (filteredArray.length === 0) return undefined;

  const sortedArray = filteredArray.sort((a, b) => a - b);
  const middleIndex = Math.floor(sortedArray.length / 2);

  if (sortedArray.length % 2 === 0) {
    return (sortedArray[middleIndex - 1] + sortedArray[middleIndex]) / 2;
  } else {
    return sortedArray[middleIndex];
  }
};

export const calculateAverage = (data: number[]): number | undefined => {
  const filteredData = data.filter(
    value => value !== undefined && !isNaN(value) && value !== null
  );

  if (filteredData.length === 0) return undefined;

  const sum = filteredData.reduce((acc, value) => acc + value, 0);

  const average = sum / filteredData.length;

  return average;
};

export const calculateHeadcountDistribution = (
  gAHead: number,
  rDHead: number,
  sMHead: number
) => {
  const sumOfCategories = gAHead + rDHead + sMHead;
  return {
    gA: sumOfCategories > 0 ? (gAHead / sumOfCategories) * 100 : 0,
    rD: sumOfCategories > 0 ? (rDHead / sumOfCategories) * 100 : 0,
    sM: sumOfCategories > 0 ? (sMHead / sumOfCategories) * 100 : 0
  };
};
