const Footer = () => {
  const openOsanoDrawer = () => {
    (window as any).Osano.cm.showDrawer();
  };

  return (
    <footer
      iconiq-theme='light-background'
      className='section_footer !bg-green-50'
      style={{
        transform:
          'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        transformStyle: 'preserve-3d',
        opacity: 1
      }}
    >
      <div className='px-20 padding-section-small'>
        <div className='w-full relative'>
          <div className='footer_top-wrapper'>
            <div className='footer_top-col'>
              <div className='margin-bottom margin-xsmall'>
                <h2 className='heading-style-h4'>Contact</h2>
              </div>
              <ul role='list' className='w-list-unstyled'>
                <li className='footer_link-list-item'>
                  <a
                    href='https://www.iconiqcapital.com/careers'
                    className='footer_link'
                  >
                    Careers
                  </a>
                </li>
                <li className='footer_link-list-item'>
                  <a
                    href='https://www.iconiqcapital.com/email'
                    className='footer_link'
                  >
                    Email
                  </a>
                </li>
              </ul>
            </div>
            <div className='footer_top-col'>
              <div className='margin-bottom margin-xsmall'>
                <h2 className='heading-style-h4'>Legal</h2>
              </div>
              <ul role='list' className='w-list-unstyled'>
                <li className='footer_link-list-item'>
                  <a
                    href='https://www.iconiqcapital.com/privacy/privacy-policies'
                    className='footer_link'
                  >
                    Privacy Policies
                  </a>
                </li>
                <li className='footer_link-list-item'>
                  <a
                    href='https://www.iconiqcapital.com/privacy/terms-of-use'
                    className='footer_link'
                  >
                    Terms of Use
                  </a>
                </li>
                <li className='footer_link-list-item'>
                  <a
                    href='https://www.iconiqcapital.com/privacy/form-crs'
                    className='footer_link'
                  >
                    Form CRS
                  </a>
                </li>
                <li className='footer_link-list-item'>
                  <a
                    className='footer_link is-cookies-button cursor-pointer'
                    onClick={openOsanoDrawer}
                  >
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='margin-vertical margin-medium'>
            <div
              iconiq-theme='normal-background'
              className='divider-line footer-div !bg-green-400'
            ></div>
          </div>
          <div className='footer_bottom-wrapper'>
            <div className='footer_disclaimer-wrap'>
              <p className='text-size-small'>
                ICONIQ Growth is a technology focused direct investment effort
                that is affiliated with ICONIQ Capital. The statements include
                herein by ICONIQ Growth are part of its outreach and engagement
                with prospective portfolio companies and investment partners.
                ICONIQ Growth's efforts are separate from the advisory services
                provided by other divisions of ICONIQ Capital, and none of the
                statements contained herein are designed to be, nor should they
                be construed as, advisory communications or advertisements of
                any investment management or advisory services. Correspondingly,
                any references to investments included above are not designed to
                be representative of the investing program applicable to any
                ICONIQ Capital client or fund investor, and any lists or
                presentations of ICONIQ Growth investments do not include all
                investments made by ICONIQ Capital. Actual performance of any
                such investing program or any specific investment made by ICONIQ
                may be worse or better compared to the performance described by
                the tool. <br />
                <br /> This website is for general information purposes only and
                is subject to change without notice. Nothing on this website
                should be construed as an offer, invitation or general
                solicitation to buy or sell any investment or securities,
                provide investment advisory services, make personal
                recommendations or to engage in any other transaction, and must
                not be relied upon in connection with any investment decision.
                Information on this website is not intended to and does not
                constitute financial, accounting, tax, legal, investment,
                consulting or other professional advice or services.  <br />
                <br /> No information on this website constitutes a financial
                promotion within the meaning of section 21 of the UK Financial
                Service and Markets Act 2000 (as amended).  <br />
                <br /> Any communications on this website are directed at, and
                intended for, only persons who are experienced institutional or
                professional investors (“professional investors”) as defined by
                applicable law and regulation. Any person that is not a
                professional investor is not an intended recipient of the
                communications on this website.   <br />
                <br /> ICONIQ Capital’s policies for how it uses information
                that you provide, or that it collects through your use of its
                website are incorporated by reference for all purposes, and can
                be found here. Please note that by visiting ICONIQ Capital’s
                website, you agree to and acknowledge its terms of use and
                privacy policies.
              </p>
            </div>
            <div
              id='w-node-e57ce4e8-1bc5-06c1-a534-8dfdc10d2efe-c10d2ed3'
              className='is-current-year w-embed'
            >
              <p className='text-size-small'>
                © <span id='current-year'>2024</span> ICONIQ Capital, LLC
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
