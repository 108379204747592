import DownIcon from 'assets/icons/down.svg';
import UpIcon from 'assets/icons/up.svg';
import { IFullWidthSection } from 'interfaces/components/fullWidthSection';
import { useState } from 'react';

const FullWidthSection = ({
  title,
  icon,
  description,
  isAccordion,
  children,
  id
}: IFullWidthSection) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(prev => !prev);
  };

  return (
    <div
      className={`w-full flex flex-col justify-start items-center ${
        isAccordion ? 'bg-white p-6 rounded-lg' : 'bg-transparent'
      }`}
    >
      <div
        className='w-full flex flex-row justify-between cursor-pointer'
        onClick={toggleExpanded}
        id={id}
      >
        <div className='flex flex-col items-start gap-4'>
          <div className='flex flex-row gap-2'>
            {icon && <img src={icon} alt='' />}
            <p className='text-3xl font-light'>{title}</p>
          </div>
          <p className='text-base font-light'>{description}</p>
        </div>
        {isAccordion &&
          (expanded ? (
            <img className='cursor-pointer' src={UpIcon} alt='' />
          ) : (
            <img className='cursor-pointer' src={DownIcon} alt='' />
          ))}
      </div>

      {(!isAccordion || (isAccordion && expanded)) && (
        <div className='relative flex flex-col w-full h-fit  mt-10 justify-between items-stretch'>
          {children}
        </div>
      )}
    </div>
  );
};

export default FullWidthSection;
