import { ICard } from 'interfaces/components/card';

const Card = ({ value, description, onUpdate, noBorder }: ICard) => {
  return (
    <div
      className={`flex flex-col gap-2 items-start w-[218px] pr-6 ${
        noBorder ? '' : 'border-r border-solid border-r-gray-400'
      } flex-1`}
    >
      <div className='flex flex-row w-full justify-between items-center'>
        <p className='text-[28px] font-semibold whitespace-nowrap'>{value}</p>
        {value === 'N/A' && (
          <p
            className='text-sm text-gray-500 cursor-pointer hover:underline whitespace-nowrap'
            onClick={onUpdate}
          >
            Update your data
          </p>
        )}
      </div>

      <p className='text-lg font-light opacity-70 whitespace-nowrap'>
        {description}
      </p>
    </div>
  );
};

export default Card;
