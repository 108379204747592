import { Page, Text, View } from '@react-pdf/renderer';
import { PdfHeader } from '../pdfHeader';
import { PdfChart } from '../pdfChart';
import { PdfFooter } from '../pdfFooter';
import { FilterOption } from 'interfaces/components/filter';
import {
  growthMotionFilterOptions,
  primaryCustomerFilterOptions,
  softwareSectorFilterOptions
} from 'utils/constants';
import Filters from './filters';

export interface IPage {
  image: string;
  pageNumber: number;
  title: string;
  companyInfo?: any;
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
}

export const ChartPage = ({
  image,
  pageNumber,
  title,
  softwareSector,
  growthMotion,
  primaryCustomer
}: IPage) => {
  return (
    <Page size='A4' orientation='landscape'>
      <View style={{ width: '100%' }}>
        <PdfHeader />
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingHorizontal: '16px'
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Text style={{ fontSize: '20px' }}>Selected Filters</Text>
            <Text style={{ fontSize: '11px', color: '#5C5C5C' }}>
              Below, you’ll find the user parameters you provided, along with
              corresponding graphs that visualize these comparisons. Note:
              Selected filters are shown in bold with a bullet point
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '150px',
              paddingVertical: '4px',
              paddingLeft: '24px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              border: '1px solid #DDDDDD',
              borderRadius: '6px',
              width: '100%',
              height: '90px'
            }}
          >
            <Filters
              options={softwareSectorFilterOptions}
              selected={softwareSector}
              title='Software Sector'
            />
            <Filters
              options={primaryCustomerFilterOptions}
              selected={primaryCustomer}
              title='Primary Customer'
            />
            <Filters
              options={growthMotionFilterOptions}
              selected={growthMotion}
              title='Growth motion'
            />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}
          >
            <PdfChart title={title} img={image} />
            <View>
              <PdfFooter pageNumber={pageNumber} />
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};
