import CustomTooltip from 'components/Tooltip';
import HelpIcon from 'assets/icons/help-black.svg';
import { IModalTextField } from 'interfaces/components/modalTextField';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import isURL from 'validator/es/lib/isURL';
import { formatMoney } from 'utils/helpers';
import ConfirmWarningIcon from 'assets/icons/confirm_warning.svg';

const ModalTextField = ({
  label,
  tooltip,
  character,
  isMoney,
  name,
  value,
  onChange,
  placeholder,
  isNumber,
  isNegative,
  isDecimal,
  isNonZero,
  isWebsite,
  min,
  max,
  minWarning,
  maxWarning,
  warning,
  disabled
}: IModalTextField) => {
  const fakeSpan = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(0);
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isWarningConfirmed, setIsWarningConfirmed] = useState(false);

  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNumber || isMoney) {
      let currentString = event.target.value.replaceAll(/,/g, '');
      if (currentString.length > 1 && isNegative) {
        currentString =
          currentString.charAt(0) === '0'
            ? currentString.substring(1)
            : currentString;
      }
      let currentNumber;
      if (isDecimal && currentString.includes('.')) {
        const checkDecimal = currentString.split('.');
        if (checkDecimal[1].length > 1) {
          return;
        }
      }

      if (isNegative) {
        if (!isDecimal) {
          currentString =
            currentString.charAt(currentString.length - 1) === '.'
              ? currentString.slice(0, -1)
              : currentString;
        }
        currentNumber = Number(
          currentString === '-' ? currentString + '1' : currentString
        );
      } else if (isDecimal) {
        currentNumber = Number(
          currentString.charAt(currentString.length - 1) === '.'
            ? currentString + '0'
            : currentString
        );
      } else {
        currentString =
          currentString.charAt(currentString.length - 1) === '.'
            ? currentString.slice(0, -1)
            : currentString;
        currentNumber = Number(currentString);
      }

      if (!isNaN(currentNumber)) {
        if (!((min && currentNumber < min) || (max && currentNumber > max))) {
          if (!(isNonZero && currentNumber === 0)) {
            onChange?.(currentString);
          } else {
            onChange('');
          }
        } else {
          return;
        }
        if (
          ((minWarning !== undefined && currentNumber < minWarning) ||
            (maxWarning !== undefined && currentNumber > maxWarning)) &&
          currentString
        ) {
          setIsWarningActive(true);
        } else {
          setIsWarningActive(false);
        }
      }
    } else {
      if (
        isWebsite &&
        !isURL(event.target.value, { require_protocol: false }) &&
        !!event.target.value
      ) {
        setIsWarningActive(true);
      } else {
        setIsWarningActive(false);
      }
      onChange?.(event.target.value);
    }
  };

  const onBlur = () => {
    if (isMoney || isNumber) {
      const currentNumber = Number(value);
      if (isNaN(currentNumber)) onChange('');
      if (isDecimal) {
        const currentString = String(value);
        if (currentString.charAt(currentString.length - 1) === '.') {
          onChange(currentString.slice(0, -1));
        }
      }
    }
  };

  const onWarningConfirmed = () => {
    setIsWarningConfirmed(true);
  };

  useEffect(() => {
    if (fakeSpan && fakeSpan.current) {
      setWidth(fakeSpan.current.offsetWidth);
    }
  }, [value]);

  return (
    <div className='w-full flex flex-col items-start gap-2'>
      <div className='flex flex-row items-center justify-start gap-1'>
        <p className='text-base font-light'>{label}</p>
        {!!tooltip && (
          <CustomTooltip
            id={label}
            content={tooltip}
            icon={HelpIcon}
            className='w-4 h-4'
          />
        )}
      </div>
      <div
        className={`border ${
          isWarningActive && !isWarningConfirmed
            ? 'border-black'
            : 'border-gray-400'
        }  border-solid w-full h-11 pl-4 rounded-lg flex flex-row items-center justify-between ${
          disabled ? 'bg-gray-200' : ''
        }`}
      >
        <div
          className={`flex ${
            isMoney ? 'flex-row-reverse' : 'flex-row'
          } items-center gap-1 h-full w-[calc(100%-16px)] relative`}
        >
          <span ref={fakeSpan} className='absolute opacity-0 -z-[100]'>
            {value}
          </span>
          <input
            className='min-w-[1px] w-full h-full focus:outline-none placeholder:text-gray-500 relative text-lg placeholder:text-lg focus:text-lg disabled:cursor-not-allowed'
            type='text'
            name={name}
            value={
              isMoney && value
                ? Number(String(value).replaceAll(/,/g, '')).toLocaleString()
                : value
            }
            onChange={onValueChange}
            onBlur={onBlur}
            placeholder={placeholder}
            maxLength={isMoney || isNumber ? 20 : undefined}
            disabled={disabled}
          />
          {!!character && (
            <p
              className={`text-lg font-light text-gray-500 ${
                isNumber ? 'absolute' : ''
              }`}
              style={{ left: `${!!width ? width + 4 : 12}px` }}
            >
              {character}
            </p>
          )}
        </div>
        {isMoney && (
          <div className='w-[100px] h-full flex flex-row items-center justify-center px-4 py-2 bg-gray-200 border-l border-solid border-gray-400 rounded-r-lg'>
            <p className='text-lg font-light text-nowrap'>
              {character + ' ' + (value ? formatMoney(Number(value), 2) : 0)}
            </p>
          </div>
        )}
      </div>
      {isWarningActive && warning && !isWarningConfirmed && (
        <div className='flex flex-row items-start gap-2'>
          <p className='text-sm font-light'>
            {isWebsite ? warning : warning + ' ' + value + character + '?'}
          </p>
          <img
            src={ConfirmWarningIcon}
            alt=''
            className='cursor-pointer'
            onClick={onWarningConfirmed}
          />
        </div>
      )}
    </div>
  );
};

export default ModalTextField;
