import { Text, View } from '@react-pdf/renderer';
import { Bullet } from './bullet';
import { FilterOption } from 'interfaces/components/filter';

interface IFilters {
  options: FilterOption[];
  selected: FilterOption[];
  title: string;
}

const Filters = ({ options, selected, title }: IFilters) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      <Text
        style={{
          fontSize: '11px',
          color: '#5C5C5C',
          marginBottom: '6px'
        }}
      >
        {title}
      </Text>
      {options.map(option => {
        const isFound = selected.find(
          selectedItem => selectedItem.value === option.value
        );
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '6px'
            }}
          >
            {isFound && <Bullet />}
            <Text
              style={{
                fontSize: '11px',
                color: isFound ? '#151515' : '#9B9B9B'
              }}
            >
              {option.label}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default Filters;
