import CloseIcon from 'assets/icons/close.svg';
import ConfirmWarningIcon from 'assets/icons/confirm_warning.svg';
import InfoIcon from 'assets/icons/help-black.svg';
import ResetIcon from 'assets/icons/reset.svg';
import MailIcon from 'assets/icons/subscribe.svg';
import { encode } from 'base-64';
import CalculatedField from 'components/CalculatedField';
import ConfirmationModal from 'components/ConfirmationModal';
import ModalSlider from 'components/ModalSlider';
import ModalTextField from 'components/ModalTextField';
import Radio from 'components/Radio';
import TextField from 'components/TextField';
import { IDataEntryModal } from 'interfaces/components/dataEntryModal';
import { sendData } from 'network/sendData';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from 'store';
import {
  resetCompanyInfo,
  setCompanyDetails,
  setCompanyEmail,
  setIsEntered,
  setOtherInfo,
  setRequiredInfo
} from 'store/companyInfo/companyInfo.slice';
import {
  companyStatus,
  modalModes,
  modalSections,
  urlParamNames
} from 'utils/constants';
import isEmail from 'validator/es/lib/isEmail';

const DataEntryModal = ({
  isOpen,
  onClose,
  mode,
  onGrowthEnterpriseReset,
  filtersCustomerSegment,
  filtersGrowthMotion,
  filtersSoftwareSector
}: IDataEntryModal) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [email, setEmail] = useState('');
  const [selected, setSelected] = useState(modalSections.COMPANY_DETAILS);

  const companyInfo = useAppSelector(state => state.companyInfo);

  const companyDetailsRef = useRef<HTMLDivElement>(null);
  const requiredInfoRef = useRef<HTMLDivElement>(null);
  const otherInfoRef = useRef<HTMLDivElement>(null);
  const refsMapper = {
    [modalSections.COMPANY_DETAILS]: companyDetailsRef,
    [modalSections.REQUIRED_INFORMATION]: requiredInfoRef,
    [modalSections.OTHER_INFORMATION]: otherInfoRef
  };

  const [companyDetailsParentRef, companyDetailsInView] = useInView({
    threshold: 0
  });
  const [requiredInfoParentRef, requiredInfoInView] = useInView({
    threshold: 0
  });
  const [otherInfoParentRef, otherInfoInView] = useInView({ threshold: 0 });

  const [currentCompanyStatus, setCompanyStatus] =
    useState<companyStatus | null>(null);
  const [currentARR, setCurrentARR] = useState<string | null>(null);
  const [yoyARR, setYoyARR] = useState<string | null>(null);
  const [fcfMargin, setFcfMargin] = useState<string | null>(null);
  const [totalHeadcount, setTotalHeadcount] = useState<string | null>(null);

  const [companyWebsite, setCompanyWebsite] = useState('');
  const [grossMargin, setGrossMargin] = useState<string | null>(null);
  const [netDollarRetention, setNetDollarRetention] = useState<string | null>(
    null
  );
  const [netMagicNumber, setNetMagicNumber] = useState<string | null>(null);
  const [burnMultiple, setBurnMultiple] = useState<string | null>(null);
  const [quickRatio, setQuickRatio] = useState<string | null>(null);

  const [rD, setRD] = useState<string | null>(null);
  const [gA, setGA] = useState<string | null>(null);
  const [sM, setSM] = useState<string | null>(null);

  const [rDHead, setRDHead] = useState<string | null>(null);
  const [gAHead, setGAHead] = useState<string | null>(null);
  const [sMHead, setSMHead] = useState<string | null>(null);

  const [headWarningConfirmed, setHeadWarning] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const onClearForm = () => {
    setConfirmationModalOpen(true);
  };

  const onConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const onHeadWarningConfirmed = () => {
    setHeadWarning(true);
  };

  useEffect(() => {
    window.onbeforeunload = e => {
      if (isOpen && isFormDirty) {
        e.preventDefault();
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isFormDirty]);

  useEffect(() => {
    setIsFormDirty(isDirty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCompanyStatus,
    currentARR,
    yoyARR,
    fcfMargin,
    totalHeadcount,
    companyWebsite,
    grossMargin,
    netDollarRetention,
    netMagicNumber,
    burnMultiple,
    quickRatio,
    rD,
    gA,
    sM,
    rDHead,
    gAHead,
    sMHead,
    email
  ]);

  useEffect(() => {
    if (companyDetailsInView) {
      setSelected(modalSections.COMPANY_DETAILS);
    } else if (requiredInfoInView) {
      setSelected(modalSections.REQUIRED_INFORMATION);
    } else {
      setSelected(modalSections.OTHER_INFORMATION);
    }
  }, [companyDetailsInView, requiredInfoInView, otherInfoInView]);

  useEffect(() => {
    if (mode === modalModes.EDIT) {
      setCompanyStatus(
        companyInfo.companyDetails.status
          ? companyInfo.companyDetails.status
          : null
      );
      setCurrentARR(
        companyInfo.requiredInfo.currentARR !== null
          ? String(companyInfo.requiredInfo.currentARR)
          : null
      );
      setYoyARR(
        companyInfo.requiredInfo.yoyARR !== null
          ? String(companyInfo.requiredInfo.yoyARR)
          : null
      );
      setFcfMargin(
        companyInfo.requiredInfo.fcfMargin !== null
          ? String(companyInfo.requiredInfo.fcfMargin)
          : null
      );
      setTotalHeadcount(
        companyInfo.requiredInfo.totalHeadcount !== null
          ? String(companyInfo.requiredInfo.totalHeadcount)
          : null
      );
      setCompanyWebsite(companyInfo.otherInfo.companyWebsite);
      setGrossMargin(
        companyInfo.otherInfo.grossMargin !== null
          ? String(companyInfo.otherInfo.grossMargin)
          : null
      );
      setNetDollarRetention(
        companyInfo.otherInfo.netDollarRetention !== null
          ? String(companyInfo.otherInfo.netDollarRetention)
          : null
      );
      setNetMagicNumber(
        companyInfo.otherInfo.netMagicNumber !== null
          ? String(companyInfo.otherInfo.netMagicNumber)
          : null
      );
      setBurnMultiple(
        companyInfo.otherInfo.burnMultiple !== null
          ? String(companyInfo.otherInfo.burnMultiple)
          : null
      );
      setQuickRatio(
        companyInfo.otherInfo.quickRatio !== null
          ? String(companyInfo.otherInfo.quickRatio)
          : null
      );
      setRD(
        companyInfo.otherInfo.rD !== null
          ? String(companyInfo.otherInfo.rD)
          : null
      );
      setGA(
        companyInfo.otherInfo.gA !== null
          ? String(companyInfo.otherInfo.gA)
          : null
      );
      setSM(
        companyInfo.otherInfo.sM !== null
          ? String(companyInfo.otherInfo.sM)
          : null
      );
      setRDHead(
        companyInfo.otherInfo.rDHead !== null
          ? String(companyInfo.otherInfo.rDHead)
          : null
      );
      setGAHead(
        companyInfo.otherInfo.gAHead !== null
          ? String(companyInfo.otherInfo.gAHead)
          : null
      );
      setSMHead(
        companyInfo.otherInfo.sMHead !== null
          ? String(companyInfo.otherInfo.sMHead)
          : null
      );
      setEmail(companyInfo.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onCurrentArrChange = (value: string) => {
    setCurrentARR(value);
  };

  const onYoyARRChange = (value: string) => {
    setYoyARR(value);
  };

  const onFcfMarginChange = (value: string) => {
    setFcfMargin(value);
  };

  const onTotalHeadcountChange = (value: string) => {
    setTotalHeadcount(value);
  };

  const onCompanyWebsiteChange = (value: string) => {
    setCompanyWebsite(value);
  };

  const onGrossMarginChange = (value: string) => {
    setGrossMargin(value);
  };

  const onNetDollarRetentionChange = (value: string) => {
    setNetDollarRetention(value);
  };

  const onNetMagicNumberChange = (value: string) => {
    setNetMagicNumber(value);
  };

  const onBurnMultipleChange = (value: string) => {
    setBurnMultiple(value);
  };

  const onQuickRatioChange = (value: string) => {
    setQuickRatio(value);
  };

  const onRDChange = (value: number | number[] | string) => {
    if (typeof value === 'number') {
      setRD(String(value));
    } else if (typeof value === 'string') {
      setRD(value);
    }
  };

  const onGAChange = (value: number | number[] | string) => {
    if (typeof value === 'number') {
      setGA(String(value));
    } else if (typeof value === 'string') {
      setGA(value);
    }
  };
  const onSMChange = (value: number | number[] | string) => {
    if (typeof value === 'number') {
      setSM(String(value));
    } else if (typeof value === 'string') {
      setSM(value);
    }
  };

  const onRDHeadChange = (value: string) => {
    setRDHead(value);
  };

  const onGAHeadChange = (value: string) => {
    setGAHead(value);
  };

  const onSMHeadChange = (value: string) => {
    setSMHead(value);
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSectionSelect = (selectedSection: modalSections) => {
    refsMapper[selectedSection].current?.scrollIntoView();
    setSelected(selectedSection);
  };

  const onCompanyStatusChange = (newStatus: companyStatus) => {
    setCompanyStatus(newStatus);
  };

  const dispatch = useAppDispatch();

  const isDirty = () => {
    if (mode === modalModes.EDIT) {
      return (
        currentCompanyStatus != companyInfo.companyDetails.status ||
        currentARR != companyInfo.requiredInfo.currentARR ||
        yoyARR != companyInfo.requiredInfo.yoyARR ||
        fcfMargin != companyInfo.requiredInfo.fcfMargin ||
        totalHeadcount != companyInfo.requiredInfo.totalHeadcount ||
        companyWebsite !== companyInfo.otherInfo.companyWebsite ||
        grossMargin != companyInfo.otherInfo.grossMargin ||
        netDollarRetention != companyInfo.otherInfo.netDollarRetention ||
        netMagicNumber != companyInfo.otherInfo.netMagicNumber ||
        burnMultiple != companyInfo.otherInfo.burnMultiple ||
        quickRatio != companyInfo.otherInfo.quickRatio ||
        rD != companyInfo.otherInfo.rD ||
        gA != companyInfo.otherInfo.gA ||
        sM != companyInfo.otherInfo.sM ||
        rDHead != companyInfo.otherInfo.rDHead ||
        gAHead != companyInfo.otherInfo.gAHead ||
        sMHead != companyInfo.otherInfo.sMHead ||
        email !== companyInfo.email
      );
    } else {
      return (
        !!currentCompanyStatus ||
        !!currentARR ||
        !!yoyARR ||
        !!fcfMargin ||
        !!totalHeadcount ||
        !!companyWebsite ||
        !!grossMargin ||
        !!netDollarRetention ||
        !!netMagicNumber ||
        !!burnMultiple ||
        !!quickRatio ||
        !!rD ||
        !!gA ||
        !!sM ||
        !!rDHead ||
        !!gAHead ||
        !!sMHead ||
        !!email
      );
    }
  };

  const isRequiredEmpty = () => {
    return (
      !currentCompanyStatus ||
      (currentCompanyStatus === companyStatus.PRIVATE && !isEmail(email)) ||
      !currentARR ||
      !yoyARR ||
      !Number(fcfMargin) ||
      !totalHeadcount
    );
  };

  const isApplyDisabled = () => {
    return isRequiredEmpty();
  };

  const isUpdateDisabled = () => {
    if (
      (currentCompanyStatus == companyInfo.companyDetails.status &&
        currentARR == companyInfo.requiredInfo.currentARR &&
        yoyARR == companyInfo.requiredInfo.yoyARR &&
        fcfMargin == companyInfo.requiredInfo.fcfMargin &&
        totalHeadcount == companyInfo.requiredInfo.totalHeadcount &&
        companyWebsite === companyInfo.otherInfo.companyWebsite &&
        grossMargin == companyInfo.otherInfo.grossMargin &&
        netDollarRetention == companyInfo.otherInfo.netDollarRetention &&
        netMagicNumber == companyInfo.otherInfo.netMagicNumber &&
        burnMultiple == companyInfo.otherInfo.burnMultiple &&
        quickRatio == companyInfo.otherInfo.quickRatio &&
        rD == companyInfo.otherInfo.rD &&
        gA == companyInfo.otherInfo.gA &&
        sM == companyInfo.otherInfo.sM &&
        rDHead == companyInfo.otherInfo.rDHead &&
        gAHead == companyInfo.otherInfo.gAHead &&
        sMHead == companyInfo.otherInfo.sMHead &&
        email === companyInfo.email) ||
      isRequiredEmpty()
    ) {
      return true;
    }
    return false;
  };

  const updateURLParams = () => {
    const url = new URL(document.URL);
    const urlParams = new URLSearchParams();
    urlParams.set(urlParamNames.COMPANY_STATUS, currentCompanyStatus || '');
    urlParams.set(urlParamNames.CURRENT_ARR, currentARR || '');
    urlParams.set(urlParamNames.YOY_ARR, yoyARR || '');
    urlParams.set(urlParamNames.FCF_MARGIN, fcfMargin || '');
    urlParams.set(urlParamNames.TOTAL_HEADCOUNT, totalHeadcount || '');
    urlParams.set(urlParamNames.COMPANY_WEBSITE, companyWebsite);
    urlParams.set(urlParamNames.GROSS_MARGIN, grossMargin || '');
    urlParams.set(urlParamNames.NET_DOLLAR_RETENTION, netDollarRetention || '');
    urlParams.set(urlParamNames.NET_MAGIC_NUMBER, netMagicNumber || '');
    urlParams.set(urlParamNames.BURN_MULTIPLE, burnMultiple || '');
    urlParams.set(urlParamNames.QUICK_RATIO, quickRatio || '');
    urlParams.set(urlParamNames.RD, rD || '');
    urlParams.set(urlParamNames.GA, gA || '');
    urlParams.set(urlParamNames.SM, sM || '');
    urlParams.set(urlParamNames.RD_HEAD, rDHead || '');
    urlParams.set(urlParamNames.GA_HEAD, gAHead || '');
    urlParams.set(urlParamNames.SM_HEAD, sMHead || '');
    urlParams.set(urlParamNames.EMAIL, email);
    const encodedParams = encode(urlParams.toString());
    const newSearchParam = new URLSearchParams();
    newSearchParam.set('params', encodedParams);
    url.search = newSearchParam.toString();
    window.history.pushState({}, '', url.toString());
  };

  const saveData = () => {
    dispatch(
      setCompanyDetails({
        status: currentCompanyStatus ? currentCompanyStatus : null
      })
    );
    dispatch(
      setRequiredInfo({
        currentARR: currentARR ? Number(currentARR) : null,
        yoyARR: yoyARR ? Number(yoyARR) : null,
        fcfMargin: fcfMargin ? Number(fcfMargin) : null,
        totalHeadcount: totalHeadcount ? Number(totalHeadcount) : null
      })
    );
    dispatch(
      setOtherInfo({
        companyWebsite,
        grossMargin: grossMargin ? Number(grossMargin) : null,
        netDollarRetention: netDollarRetention
          ? Number(netDollarRetention)
          : null,
        netMagicNumber: netMagicNumber ? Number(netMagicNumber) : null,
        burnMultiple: burnMultiple ? Number(burnMultiple) : null,
        quickRatio: quickRatio ? Number(quickRatio) : null,
        rD: rD ? Number(rD) : null,
        gA: gA ? Number(gA) : null,
        sM: sM ? Number(sM) : null,
        rDHead: rDHead ? Number(rDHead) : null,
        gAHead: gAHead ? Number(gAHead) : null,
        sMHead: sMHead ? Number(sMHead) : null
      })
    );
    dispatch(setCompanyEmail(email));
    dispatch(setIsEntered(true));
    updateURLParams();
  };

  const getHeadPercent = (numerator: string | null) => {
    if (!(rDHead || sMHead || gAHead)) {
      return null;
    }
    if (!numerator) return 0;
    const currRDHead = rDHead ? Number(rDHead) : 0;
    const currGAHead = gAHead ? Number(gAHead) : 0;
    const currSMHead = sMHead ? Number(sMHead) : 0;

    return (Number(numerator) / (currRDHead + currGAHead + currSMHead)) * 100;
  };

  const onApply = async () => {
    saveData();
    setIsSubmitting(true);
    const dataEntry = {
      filtersSoftwareSector: filtersSoftwareSector.map(item => item.value),
      filtersGrowthMotion: filtersGrowthMotion.map(item => item.value),
      filtersCustomerSegment: filtersCustomerSegment.map(item => item.value),
      isUpdated: false,
      email: currentCompanyStatus === companyStatus.PRIVATE ? email : '',
      url: window.location.href,
      companyStatus: currentCompanyStatus ? currentCompanyStatus : null,
      currentARR: currentARR ? Number(currentARR) : null,
      yoyARR: yoyARR ? Number(yoyARR) : null,
      fcfMargin: fcfMargin ? Number(fcfMargin) : null,
      totalHeadcount: totalHeadcount ? Number(totalHeadcount) : null,
      ruleOf40: yoyARR && fcfMargin ? Number(yoyARR) + Number(fcfMargin) : null,
      arrPerFTE:
        currentARR && totalHeadcount
          ? Number(currentARR) / Number(totalHeadcount)
          : null,
      companyWebsite: companyWebsite,
      grossMargin: grossMargin ? Number(grossMargin) : null,
      netDollarRetention: netDollarRetention
        ? Number(netDollarRetention)
        : null,
      netMagicNumber: netMagicNumber ? Number(netMagicNumber) : null,
      burnMultiple: burnMultiple ? Number(burnMultiple) : null,
      quickRatio: quickRatio ? Number(quickRatio) : null,
      rD: rD ? Number(rD) : null,
      gA: gA ? Number(gA) : null,
      sM: sM ? Number(sM) : null,
      rDHead: rDHead ? Number(rDHead) : null,
      gAHead: gAHead ? Number(gAHead) : null,
      sMHead: sMHead ? Number(sMHead) : null,
      rDHeadPercent: getHeadPercent(rDHead),
      gAHeadPercent: getHeadPercent(gAHead),
      sMHeadPercent: getHeadPercent(sMHead)
    };

    try {
      await sendData(dataEntry);
      toast.success('Data saved successfully!', {
        hideProgressBar: true
      });
    } catch (error) {
      toast.error('Failed to save data!', {
        hideProgressBar: true
      });
    }
    setIsSubmitting(false);
    onClose();
  };

  const onReset = () => {
    dispatch(resetCompanyInfo());
    setCompanyStatus(null);
    setCurrentARR(null);
    setYoyARR(null);
    setFcfMargin(null);
    setTotalHeadcount(null);
    setCompanyWebsite('');
    setGrossMargin(null);
    setNetDollarRetention(null);
    setNetMagicNumber(null);
    setBurnMultiple(null);
    setQuickRatio(null);
    setRD(null);
    setGA(null);
    setSM(null);
    setRDHead(null);
    setGAHead(null);
    setSMHead(null);
    setEmail('');
    onGrowthEnterpriseReset();
    onConfirmationModalClose();
    onClose();
  };

  const onUpdate = async () => {
    saveData();
    setIsSubmitting(true);
    const dataEntry = {
      isUpdated: true,
      filtersSoftwareSector: filtersSoftwareSector.map(item => item.value),
      filtersGrowthMotion: filtersGrowthMotion.map(item => item.value),
      filtersCustomerSegment: filtersCustomerSegment.map(item => item.value),
      email: currentCompanyStatus === companyStatus.PRIVATE ? email : '',
      url: window.location.href,
      companyStatus: currentCompanyStatus ? currentCompanyStatus : null,
      currentARR: currentARR ? Number(currentARR) : null,
      yoyARR: yoyARR ? Number(yoyARR) : null,
      fcfMargin: fcfMargin ? Number(fcfMargin) : null,
      totalHeadcount: totalHeadcount ? Number(totalHeadcount) : null,
      ruleOf40: yoyARR && fcfMargin ? Number(yoyARR) + Number(fcfMargin) : null,
      arrPerFTE:
        currentARR && totalHeadcount
          ? Number(currentARR) / Number(totalHeadcount)
          : null,
      companyWebsite: companyWebsite,
      grossMargin: grossMargin ? Number(grossMargin) : null,
      netDollarRetention: netDollarRetention
        ? Number(netDollarRetention)
        : null,
      netMagicNumber: netMagicNumber ? Number(netMagicNumber) : null,
      burnMultiple: burnMultiple ? Number(burnMultiple) : null,
      quickRatio: quickRatio ? Number(quickRatio) : null,
      rD: rD ? Number(rD) : null,
      gA: gA ? Number(gA) : null,
      sM: sM ? Number(sM) : null,
      rDHead: rDHead ? Number(rDHead) : null,
      gAHead: gAHead ? Number(gAHead) : null,
      sMHead: sMHead ? Number(sMHead) : null,
      rDHeadPercent: getHeadPercent(rDHead),
      gAHeadPercent: getHeadPercent(gAHead),
      sMHeadPercent: getHeadPercent(sMHead)
    };
    try {
      await sendData(dataEntry);
      toast.success('Data saved successfully!', {
        hideProgressBar: true
      });
    } catch (error) {
      toast.error('Failed to save data!', {
        hideProgressBar: true
      });
    }
    setIsSubmitting(false);
    onClose();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={false}
      overlayClassName='fixed inset-0 bg-gray-500/75 z-[100]'
      className='absolute bg-white w-[850px] max-h-[827px] left-0 right-0 top-6 bottom-6 m-auto my-auto rounded-lg'
    >
      <div className='w-full h-full overflow-hidden relative flex flex-col items-center'>
        <div className='sticky top-0 w-full flex flex-col items-start gap-2 justify-between px-10 py-6 border-b border-gray-400 border-solid'>
          <div className='flex flex-row items-center justify-between w-full'>
            <p className='text-3xl font-light'>
              {mode === modalModes.ADD ? 'Enter' : 'Update'} Your Data
            </p>
            <img
              className='cursor-pointer'
              src={CloseIcon}
              alt=''
              onClick={onClose}
            />
          </div>
          <p className='text-base font-light'>
            Enter your data to overlay it with the Compass SaaS benchmarks¹ and
            access more features.
          </p>
          <p className='text-base font-light mt-2'>
            Need help calculating any of the below metrics? Check out our{' '}
            <a
              href='https://www.iconiqcapital.com/growth/insights/the-saas-glossary'
              target='_blank'
              className='text-base font-light cursor-pointer'
              rel='noreferrer'
            >
              SaaS glossary
            </a>{' '}
            for recommended calculations.
          </p>
          <p className='font-light leading-tight mt-2 text-sm'>
            By entering your data, you consent to the retention by ICONIQ of
            your answers for future use. Your data will be stored securely and
            if any data is included in materials that are publicly available,
            such data is expected to be aggregated and anonymized.
          </p>
        </div>
        <div className='w-full h-full relative overflow-hidden flex flex-row'>
          <div className='flex flex-col justify-start w-[220px] border-r border-solid border-gray-400'>
            <div
              className={`w-full py-3 pl-10 text-left cursor-pointer ${
                selected === modalSections.COMPANY_DETAILS && 'bg-gray-200'
              }`}
              onClick={() => onSectionSelect(modalSections.COMPANY_DETAILS)}
            >
              <p
                className={`text-lg font-light ${
                  selected !== modalSections.COMPANY_DETAILS && 'text-gray-700'
                }`}
              >
                Company Details
              </p>
            </div>
            <div
              className={`w-full py-3 pl-10 text-left cursor-pointer ${
                selected === modalSections.REQUIRED_INFORMATION && 'bg-gray-200'
              }`}
              onClick={() =>
                onSectionSelect(modalSections.REQUIRED_INFORMATION)
              }
            >
              <p
                className={`text-lg font-light ${
                  selected !== modalSections.REQUIRED_INFORMATION &&
                  'text-gray-700'
                }`}
              >
                Required Information
              </p>
            </div>
            <div
              className={`w-full py-3 pl-10 text-left cursor-pointer ${
                selected === modalSections.OTHER_INFORMATION && 'bg-gray-200'
              }`}
              onClick={() => onSectionSelect(modalSections.OTHER_INFORMATION)}
            >
              <p
                className={`text-lg font-light ${
                  selected !== modalSections.OTHER_INFORMATION &&
                  'text-gray-700'
                }`}
              >
                Optional Information
              </p>
            </div>
          </div>
          <div className='w-[calc(100%-220px)] flex flex-col items-start gap-10 px-10 pt-6 pb-10 overflow-scroll'>
            <div ref={companyDetailsParentRef}>
              <div
                className='flex flex-col items-start gap-6'
                ref={companyDetailsRef}
              >
                <p className='text-xl font-semibold'>Company Details</p>
                <div className='flex flex-col items-start gap-2'>
                  <p className='text-base font-light'>Company Status</p>
                  <div className='flex flex-row justify-start items-center gap-6'>
                    <Radio
                      name='private'
                      value={companyStatus.PRIVATE}
                      selected={currentCompanyStatus}
                      label='Private'
                      onChange={onCompanyStatusChange}
                    />
                    <Radio
                      name='public'
                      value={companyStatus.PUBLIC}
                      selected={currentCompanyStatus}
                      label='Public'
                      onChange={onCompanyStatusChange}
                    />
                  </div>
                  {currentCompanyStatus === companyStatus.PUBLIC && (
                    <p className='text-sm font-light mt-1'>
                      Please do not provide any material non-public information
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='w-full' ref={requiredInfoParentRef}>
              <div
                className='w-full flex flex-col items-start gap-6'
                ref={requiredInfoRef}
              >
                <p className='text-xl font-semibold'>Required Information</p>
                <div className='w-full flex flex-col items-start gap-4'>
                  <ModalTextField
                    label='Current ARR or Annualized Revenue*'
                    tooltip='Please enter your current annual recurring revenue or annualized revenue'
                    character='$'
                    name='current_arr'
                    value={currentARR ? currentARR : ''}
                    onChange={onCurrentArrChange}
                    isMoney
                    placeholder='0'
                  />
                  <ModalTextField
                    label='YoY ARR or Revenue Growth*'
                    tooltip='Please enter your latest YoY ARR or revenue growth (e.g., Year 2 ARR / Year 1 ARR – 1)'
                    character='%'
                    name='yoy_arr'
                    value={yoyARR ? yoyARR : ''}
                    onChange={onYoyARRChange}
                    isNumber
                    isNegative
                    minWarning={0}
                    maxWarning={2000}
                    warning='Are you sure your YoY ARR Revenue Growth is'
                    placeholder='0'
                  />
                  <ModalTextField
                    label='FCF Margin*'
                    tooltip='Please enter your FCF margin, calculated as FCF as a % of Revenue'
                    character='%'
                    name='fcf_margin'
                    value={fcfMargin ? fcfMargin : ''}
                    onChange={onFcfMarginChange}
                    isNumber
                    isNegative
                    minWarning={0}
                    warning='Are you sure your FCF Margin is'
                    placeholder='0'
                  />
                  <ModalTextField
                    label='Total Headcount*'
                    tooltip='Please enter your total headcount including all full-time employees'
                    name='total_headcount'
                    value={totalHeadcount ? totalHeadcount : ''}
                    onChange={onTotalHeadcountChange}
                    isNumber
                    placeholder='Enter total headcount'
                  />
                  <div className='flex flex-row items-start gap-4 w-full'>
                    <CalculatedField
                      label='Rule of 40'
                      tooltip='This is calculated based on the YoY Growth rate and FCF Margin you submitted'
                      value={
                        yoyARR && fcfMargin
                          ? Number(yoyARR) + Number(fcfMargin)
                          : 0
                      }
                      character='%'
                    />
                    <CalculatedField
                      label='ARR per FTE'
                      tooltip='This is calculated based on the ARR / Revenue and Total Headcount you submitted'
                      value={
                        currentARR && totalHeadcount
                          ? Math.ceil(
                              Number(currentARR) / Number(totalHeadcount)
                            )
                          : 0
                      }
                      character='$'
                      isMoney
                      warning={
                        currentARR &&
                        totalHeadcount &&
                        Number(currentARR) / Number(totalHeadcount) > 600000
                          ? `Are you sure your ARR per FTE is $${(
                              Number(currentARR) / Number(totalHeadcount)
                            ).toLocaleString()}?`
                          : ''
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full' ref={otherInfoParentRef}>
              <div
                className='w-full flex flex-col items-start gap-6'
                ref={otherInfoRef}
              >
                <p className='text-xl font-semibold'>Optional Information</p>
                <div className='flex flex-row w-full justify-between items-start'>
                  <div className='w-[276px] flex flex-col items-start gap-4'>
                    <ModalTextField
                      label='Company Website'
                      name='company_website'
                      value={companyWebsite}
                      onChange={onCompanyWebsiteChange}
                      placeholder='Enter company website'
                      isWebsite
                      warning='Invalid website entered.'
                    />
                    <ModalTextField
                      label='Gross Margin'
                      tooltip='Please enter your gross margin, calculated as Gross Profit as a % of Revenue'
                      name='gross_margin'
                      value={grossMargin ? grossMargin : ''}
                      onChange={onGrossMarginChange}
                      character='%'
                      min={0}
                      max={100}
                      isNumber
                      placeholder='0'
                    />
                    <ModalTextField
                      label='Net Dollar Retention'
                      tooltip='Please enter your net dollar retention.  This can be calculated by looking at your LTM customer cohort retention, or calculated using this formula: 
1 + ((Expansion - Downsell - Churn) / (Average of Beginning ARR + Ending ARR))'
                      name='net_dollar_retention'
                      value={netDollarRetention ? netDollarRetention : ''}
                      onChange={onNetDollarRetentionChange}
                      character='%'
                      min={0}
                      max={200}
                      isNumber
                      minWarning={50}
                      maxWarning={150}
                      warning='Are you sure your Net Dollar Retention is'
                      placeholder='0'
                    />
                    <ModalTextField
                      label='Net Magic Number'
                      tooltip='Net magic number can be calculated as current quarter net new ARR / sales & marketing expense.  Depending on the length of your sales cycle, you can use S&M expense from the current or last quarter.'
                      name='net_magic_number'
                      value={netMagicNumber ? netMagicNumber : ''}
                      onChange={onNetMagicNumberChange}
                      character='x'
                      min={0}
                      max={10}
                      isNumber
                      isDecimal
                      maxWarning={5}
                      warning='Are you sure your Net Magic Number is'
                      placeholder='0'
                    />
                    <ModalTextField
                      label='Burn Multiple'
                      tooltip='Burn multiple can be calculated as the absolute value of your burn (usually FCF or EBITDA) divided by net new ARR in the same period. 
                      Burn multiple is generally only calculated for companies with negative free cash flow'
                      name='burn_multiple'
                      value={burnMultiple ? burnMultiple : ''}
                      onChange={onBurnMultipleChange}
                      character='x'
                      min={0}
                      max={10}
                      isNumber
                      isDecimal
                      maxWarning={5}
                      warning='Are you sure your Burn Multiple is'
                      placeholder='0'
                      disabled={
                        !!fcfMargin &&
                        !isNaN(Number(fcfMargin)) &&
                        Number(fcfMargin) > 0
                      }
                    />
                    <ModalTextField
                      label='Quick Ratio'
                      name='quick_ratio'
                      tooltip='The quick ratio measures the growth of your recurring revenue in comparison to the contraction. Quick ratio can be calculated as Gross New ARR / Total Churned ARR'
                      value={quickRatio ? quickRatio : ''}
                      onChange={onQuickRatioChange}
                      character='x'
                      min={0}
                      max={25}
                      isNumber
                      isDecimal
                      placeholder='0'
                    />
                  </div>
                  <div className='w-[234px] flex flex-col items-start gap-10'>
                    <div className='w-full flex flex-col items-start gap-4'>
                      <p className='text-base font-light'>
                        OpEx Function as a{' '}
                        <p className='underline font-bold inline'>
                          % of Revenue
                        </p>
                      </p>
                      <ModalSlider
                        label='R&D'
                        tooltip='Please drag the slider to the approximate % corresponding to your R&D operating expenses as a % of total revenue'
                        value={rD ? rD : ''}
                        onChange={onRDChange}
                        min={0}
                        max={300}
                        character='%'
                      />
                      <ModalSlider
                        label='G&A'
                        tooltip='Please drag the slider to the approximate % corresponding to your G&A operating expenses as a % of total revenue'
                        value={gA ? gA : ''}
                        onChange={onGAChange}
                        min={0}
                        max={300}
                        character='%'
                      />
                      <ModalSlider
                        label='S&M'
                        tooltip='Please drag the slider to the approximate % corresponding to your S&M operating expenses as a % of total revenue'
                        value={sM ? sM : ''}
                        onChange={onSMChange}
                        min={0}
                        max={300}
                        character='%'
                      />
                    </div>
                    <div className='w-full flex flex-col items-start gap-4'>
                      <p className='text-base font-light'>
                        Headcount Distribution (Total FTEs)
                      </p>
                      <ModalTextField
                        label='R&D'
                        tooltip='Please enter the total number of full-time employees in your R&D organization'
                        name='R&D_Head'
                        value={rDHead ? rDHead : ''}
                        onChange={onRDHeadChange}
                        isNumber
                        placeholder='Enter R&D'
                      />
                      <ModalTextField
                        label='G&A'
                        tooltip='Please enter the total number of full-time employees in your G&A organization'
                        name='G&A_Head'
                        value={gAHead ? gAHead : ''}
                        onChange={onGAHeadChange}
                        isNumber
                        placeholder='Enter G&A'
                      />
                      <div className='flex flex-col w-full items-start gap-2'>
                        <ModalTextField
                          label='S&M'
                          tooltip='Please enter the total number of full-time employees in your S&M organization'
                          name='S&M_Head'
                          value={sMHead ? sMHead : ''}
                          onChange={onSMHeadChange}
                          isNumber
                          placeholder='Enter S&M'
                        />
                        {!headWarningConfirmed &&
                          (!!rDHead || !!gAHead || !!sMHead) &&
                          Number(rDHead) + Number(gAHead) + Number(sMHead) !==
                            Number(totalHeadcount) && (
                            <div className='flex flex-row items-start gap-2'>
                              <p className='text-sm font-light'>
                                {'Are you sure total headcount is ' +
                                  (Number(rDHead) +
                                    Number(gAHead) +
                                    Number(sMHead)) +
                                  '? This is different than the Total Headcount entered above'}
                              </p>
                              <img
                                src={ConfirmWarningIcon}
                                alt=''
                                className='cursor-pointer'
                                onClick={onHeadWarningConfirmed}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sticky bottom-0 w-full flex flex-col items-end justify-between mt-auto px-10 py-6 border-t border-gray-400 border-solid'>
          <div className='w-full flex flex-row items-center justify-between'>
            <div
              className='flex flex-row items-center gap-1 cursor-pointer'
              onClick={onClearForm}
            >
              <img src={ResetIcon} alt='' />
              <p className='text-lg font-light'>Clear Form</p>
            </div>
            {mode === modalModes.ADD ? (
              <div className='w-full max-w-[400px]'>
                <TextField
                  id='applyComparisonData'
                  name='apply'
                  value={email}
                  onChange={onChangeEmail}
                  icon={MailIcon}
                  buttonLabel={isSubmitting ? 'Saving...' : 'Submit'}
                  onClick={onApply}
                  placeholder='Enter your email address'
                  disabled={isApplyDisabled() || isSubmitting}
                  tooltip={{
                    id: 'add_tooltip',
                    content:
                      'Please enter your email address to submit this form and overlay your data against our SaaS benchmarks.',
                    icon: InfoIcon
                  }}
                />
              </div>
            ) : (
              <div className='w-full max-w-[400px]'>
                <TextField
                  id='updateComparisonData'
                  name='update'
                  value={email}
                  onChange={onChangeEmail}
                  icon={MailIcon}
                  buttonLabel={isSubmitting ? 'Saving...' : 'Update'}
                  onClick={onUpdate}
                  placeholder='Enter your email address'
                  disabled={isUpdateDisabled() || isSubmitting}
                  tooltip={{
                    id: 'update_tooltip',
                    content:
                      'Please enter your email address to submit this form and overlay your data against our SaaS benchmarks.',
                    icon: InfoIcon
                  }}
                />
              </div>
            )}
          </div>
          {email && !isEmail(String(email)) && (
            <p className='text-xs font-light'>Invalid email entered</p>
          )}
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={onConfirmationModalClose}
        onReset={onReset}
      />
    </ReactModal>
  );
};

export default DataEntryModal;
