import { TooltipRenderProps } from 'react-joyride';

const CustomWalkthroughTooltip = (props: TooltipRenderProps) => {
  const {
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
    size
  } = props;

  return (
    <div
      className='w-[300px] bg-black rounded-lg p-3 flex flex-col justify-between items-start gap-3'
      {...tooltipProps}
    >
      <div className='text-white font-iconiqQuadraat text-base font-light'>
        {step.content}
      </div>
      <div className='flex flex-row justify-between items-center w-full'>
        <p className='text-base font-light text-white opacity-50'>
          {index + 1}/{size}
        </p>
        <div className='flex flex-row items-center justify-start gap-5'>
          <button className='text-white outline-0 opacity-50' {...skipProps}>
            {skipProps.title}
          </button>

          {continuous && (
            <button className='text-white outline-0' {...primaryProps}>
              {primaryProps.title}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomWalkthroughTooltip;
