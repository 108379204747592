import { Text, View } from '@react-pdf/renderer';
interface IPerformanceMetricsCard {
  percentage: string;
  text: string;
}
export const PerformanceMetricsCard = ({
  text,
  percentage
}: IPerformanceMetricsCard) => {
  return (
    <View
      style={{
        width: '124px',
        height: '50px',
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        backgroundColor: '#f2f2f2'
      }}
    >
      <Text style={{ fontSize: '18px' }}>{percentage}</Text>
      <Text style={{ fontSize: '10px', color: '#5c5c5c' }}>{text}</Text>
    </View>
  );
};
