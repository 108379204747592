import Error from 'assets/icons/error.svg';
import 'chart.js/auto';
import { Chart as ChatJS } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FilterOption } from 'interfaces/components/filter';
import { useState } from 'react';
import { Bar, Bubble } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { RootState } from 'store';
import { getCustomOptions } from 'utils/chart-options/bar-chart-options';
import {
  barStackedChartOptions,
  totalValuePlugin
} from 'utils/chart-options/bar-stacked-chart';
import { bubbleChartOptions } from 'utils/chart-options/bubble-charts';
import { Dataset, getDataCharts } from 'utils/chart-options/chart-collection';
import { ChartDataMap, ChartLabelsV2 } from 'utils/helpers/mapChartsData';
import ChartContainer from './ChartContainer';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './TabComponent';
ChatJS.register(ChartDataLabels);
interface ISectionCharts {
  chartDataMap: ChartDataMap[];
  arr: FilterOption[];
  growthMotion: FilterOption[];
  softwareSector: FilterOption[];
  primaryCustomer: FilterOption[];
  isCompanyDataEntered: boolean;
}
const SectionCharts = ({
  chartDataMap,
  arr,
  growthMotion,
  softwareSector,
  primaryCustomer,
  isCompanyDataEntered
}: ISectionCharts) => {
  const requiredInfo = useSelector(
    (state: RootState) => state.companyInfo.requiredInfo
  );
  const otherInfo = useSelector(
    (state: RootState) => state.companyInfo.otherInfo
  );

  const companyMetrics = { ...requiredInfo, ...otherInfo };
  const [loading, setLoading] = useState(false);
  const [showCorner, setShowCorner] = useState(0);
  const [switchValue, setSwitchValue] = useState(0);
  const [downloadIndex, setDownloadIndex] = useState(0);
  const { barChartData, bubbleChartData } = getDataCharts(
    switchValue,
    chartDataMap,
    companyMetrics,
    arr,
    growthMotion,
    softwareSector,
    primaryCustomer
  );
  const hasData = (dataset: Dataset, isRuleof40?: boolean) => {
    if (isRuleof40) {
      // super hacky
      // temp until refactor
      // trying to accommodate:
      //  - never showing <$25M ARR group (ie: r == 10 group) due to currently large outlier data
      //  - client recently removed this group from data entirely
      //    - but we are also _typically_ hiding entire chart if _any_ ARR range is missing
      //    - so since that would cause Rule of 40 to never show, we are specially handling this case
      const datasetRemovedR10 = {
        label: dataset.label,
        data: dataset.data.filter((item: any) => item && item.r !== 10),
        backgroundColor: dataset.backgroundColor
      };

      return (
        datasetRemovedR10 &&
        dataset.data.length > 0 &&
        dataset.data.filter(
          (item: any) =>
            item && item.x !== null && item.y !== null && item.ruleOf40 !== null
        ).length > 0 // temp until we refactor
        // ).length === (arr.length > 0 ? arr.length : ChartLabelsV2.length)
      );
    }
    return (
      dataset &&
      dataset.data.length > 0 &&
      dataset.data.length ===
        (arr.length > 0 ? arr.length : ChartLabelsV2.length) &&
      !dataset?.data.includes(null) &&
      !dataset?.data?.includes(undefined)
    );
  };
  const onDownload = () => {
    setDownloadIndex(1);
    setLoading(true);
  };
  const onDownloadDone = () => {
    setDownloadIndex(0);
    setLoading(false);
  };

  const chartData = [
    {
      title: barChartData[0]?.title,
      color: barChartData[0]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'We believe one of the most important metrics to track is ARR growth, which measures the change in ARR compared to the previous year. The drivers of ARR growth are also important to understand – both in terms of size and quality of customers, as well as variation by sales motion.',
      component: hasData(barChartData[0].datasets[0]) ? (
        <Bar
          data={barChartData[0]}
          options={getCustomOptions(true)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[1]?.title,
      color: barChartData[1]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'Net retention is arguably the most important gauge of business health for software companies and the efficiency of their revenue generation. Net dollar retention specifically accounts for expansion, downsell, and churn which renders it a robust measure of both product strength and customer success motions. There are a number of different ways to calculate net retention. Compass SaaS benchmarks reflect annualized quarterly net dollar retention.',
      component: hasData(barChartData[1].datasets[0]) ? (
        <Bar
          data={barChartData[1]}
          options={getCustomOptions(true)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[2]?.title,
      color: barChartData[2]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'The “magic” of this metric lies in its ability to measure revenue generation against sales & marketing spend while accounting for the lag of a typical sales cycle in order to understand the efficiency of sales and marketing spend and teams at a more nuanced level. ',
      component: hasData(barChartData[2].datasets[0]) ? (
        <Bar
          data={barChartData[2]}
          options={getCustomOptions(false)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[3]?.title,
      color: barChartData[3]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'Gross margin we believe is vital for SaaS companies as it indicates profitability and scalability. Software companies typically have ~70%+ gross margins and are able to reduce COGS on a per unit basis as they scale. It is important to note a company’s growth motion and sector should be considered when benchmarking gross margin.',
      component: hasData(barChartData[3].datasets[0]) ? (
        <Bar
          data={barChartData[3]}
          options={getCustomOptions(true)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: bubbleChartData[0]?.title,
      color: bubbleChartData[0]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'The Rule of 40 is a rule of thumb that measures the tradeoff between growth and profitability; a high-performing SaaS company should generally meet or exceed 40%. Typically real weight begins to be placed against this metric for companies with at least ~$25M in topline revenue.',
      component: hasData(bubbleChartData[0].datasets[0], true) ? (
        <Bubble
          data={bubbleChartData[0]}
          options={bubbleChartOptions}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[4]?.title,
      color: barChartData[4]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'Burn multiple is one of many ways to measure capital efficiency - a metric that becomes increasingly important in the current environment.  A burn multiple under 1.5x is generally a great goal for companies to strive toward that are experiencing strong growth.',
      component: hasData(barChartData[4].datasets[0]) ? (
        <Bar
          data={barChartData[4]}
          options={getCustomOptions(false)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[5]?.title,
      color: barChartData[5]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'ARR per FTE is another efficiency metric that measures how much ARR is generated per full-time employee. It serves as an effective gauge of whether a company is burning too much relative to employee productivity or if there is an opportunity to invest further.',
      component: hasData(barChartData[5].datasets[0]) ? (
        <Bar
          data={barChartData[5]}
          options={getCustomOptions(false, true)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[6]?.title,
      color: barChartData[6]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'Over time as SaaS companies scale, revenue should generally start to outpace total operating spend once companies reach ~$200M+ in ARR. Sales and marketing typically comprises the largest proportion of total spend as companies invest in the GTM engine to fuel growth.',
      component: hasData(barChartData[6].datasets[0]) ? (
        <Bar
          data={barChartData[6]}
          options={barStackedChartOptions}
          plugins={[totalValuePlugin]}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[7]?.title,
      color: barChartData[7]?.datasets[0]?.backgroundColor,
      tooltipContent:
        "FCF margin indicates a company's ability to generate cash from its operations after accounting for capital expenditures. This reflects the true liquidity position and financial health, ensuring the company has sufficient cash to invest in growth, pay down debt, and/or return value to shareholders. In today’s macro environment, achieving profitability or having a clear path to profitability is usually a prerequisite to going public.",
      component: hasData(barChartData[7].datasets[0]) ? (
        <Bar
          data={barChartData[7]}
          options={getCustomOptions(true)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[8]?.title,
      color: barChartData[8]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'The SaaS quick ratio measures the growth of recurring revenue over a certain period in comparison to the contraction. Not all growth is equal, so it is important to maintain a higher quick ratio which implies that your growth is healthy and efficient. Generally, the "optimum" SaaS quick ratio is around 4x.',
      component: hasData(barChartData[8].datasets[0]) ? (
        <Bar
          data={barChartData[8]}
          options={getCustomOptions(false)}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    },
    {
      title: barChartData[9]?.title,
      color: barChartData[9]?.datasets[0]?.backgroundColor,
      tooltipContent:
        'Sales and marketing typically constitutes the largest proportion of an organization’s total headcount, followed by R&D. Typically R&D starts to comprise a smaller proportion of total headcount as companies invest in go-to-market excellence and operational oversight leading up to IPO.',
      component: hasData(barChartData[9].datasets[0]) ? (
        <Bar
          data={barChartData[9]}
          options={barStackedChartOptions}
          className='div2PDF'
        />
      ) : (
        <EmptyDataScreen className='div2PDF' />
      )
    }
  ];

  return (
    <section className='w-full px-20 pb-6'>
      <Tabs defaultValue='0'>
        <div className='w-full flex flex-row items-start gap-10'>
          <TabsList
            className='w-[194px] min-w-[194px] flex flex-col items-start justify-start tabs_list'
            data-orientation='vertical'
          >
            {chartData.map((item, index) => (
              <TabsTrigger
                id={`${item.title}_tab`}
                key={String(item.title + index)}
                onClick={() => setShowCorner(index)}
                value={downloadIndex ? '0' : String(index)}
                onKeyUp={e => e.preventDefault()}
                onKeyDown={e => e.preventDefault()}
                className='w-full h-10 text-left pl-5 rounded-r-lg border-l border-solid border-l-gray-300 data-[state=active]:bg-green-100 data-[state=active]:border-l-green-400 data-[state=active]:text-green-800'
              >
                {item.title}
              </TabsTrigger>
            ))}
          </TabsList>
          {chartData.map((item, index) => (
            <TabsContent
              key={String(item.title + index)}
              value={String(downloadIndex === 1 ? showCorner : index)}
              className='w-[calc(100%-194px)]'
            >
              <ChartContainer
                title={item.title}
                tooltip={item.tooltipContent}
                onDownload={onDownload}
                onSwitchChange={() =>
                  setSwitchValue(value => (value === 0 ? 1 : 0))
                }
                switchChecked={switchValue === 1}
                chartData={chartData}
                onDownloadDone={onDownloadDone}
                downloadIndex={downloadIndex}
                isCompanyDataEntered={isCompanyDataEntered}
                arr={arr}
                growthMotion={growthMotion}
                softwareSector={softwareSector}
                primaryCustomer={primaryCustomer}
                loading={loading}
              >
                {item.component}
              </ChartContainer>
            </TabsContent>
          ))}
        </div>
      </Tabs>
    </section>
  );
};

export const EmptyDataScreen = ({ className }: { className: string }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center w-full h-full gap-4 ${className}`}
    >
      {/* <div className='w-10 h-10 text-2xl rounded-full border-[2px] grid place-content-center border-gray-500 text-gray-500 font-sans '>
        !
      </div> */}
      <img src={Error} alt='error' />
      <p className='text-center text-2xl font-light'>
        There&apos;s not enough data to display this chart.
      </p>
      <div className=' text-center text-gray-500 text-base font-light leading-tight max-w-[420px]'>
        We set a minimum sample size in order to maintain data fidelity and
        privacy - please adjust the filters you’re using.
      </div>
    </div>
  );
};

export default SectionCharts;
